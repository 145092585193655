/// <reference path='../scaffolding/Page.ts'/>
/// <reference path='../scaffolding/Utils.ts'/>
// / <reference path='../partial/IsoSphere.ts'/>


class Home extends Page{
	public hasVideo:boolean;
	public lineHasGrown:boolean;
	public headerBgIsOn:boolean;
	public hexOn:boolean;
	// public sphere:Nickel.IsoSphere;

	constructor(){

		super();
		this.hasVideo = false;
		this.headerBgIsOn = false
		this.hexOn = false;

		this.lineHasGrown = false;
		$('.youtube').bind("click touchstart", (e)=>this.openVideo(e))
		ga('send', 'pageview', '/');


        var navItem = $('.navItem');
		if(this.sWidth >= 768){
			$('.container').on('scroll', (e)=>this.checkScrollPosition(e));
			navItem.addClass('hide')
			navItem.addClass('animateNavItem')

            var headerBg = $(".headerBg")
            headerBg.css('opacity', '0')
		}


        $('.ctaBtn').bind('click touchend', (e)=>this.showMore(e))
  	}
  	
  	public addVideo(){
        let videoTag:any =  $('video');
        videoTag[0].src = "/mov/home.mp4";
		videoTag[0].play();
		this.hasVideo =  true;

		if(!this.lineHasGrown){
			videoTag.on('canplay canplaythrough', function(){
				$(".ruleHolder .rule").addClass("grow");
			})
			this.lineHasGrown = true;
		}
        this.repositionVideo();
	}


	public checkScrollPosition(e){
		var target = $(e.currentTarget);
		this.animateHeader(target);

		if(!this.hexOn){
			this.animateHex()
		}
		
	}

	public animateHeader(target){
		var top = target.scrollTop();
		var overTop = $(".overview").offset().top;
		var diff = top-overTop;
        var headerBg = $(".headerBg")

		if(top >= 20 && !this.headerBgIsOn){
            headerBg.addClass('showBack')
            headerBg.removeClass('hideBack')
			this.headerBgIsOn = true;
		} else if(top <= 20 && this.headerBgIsOn){
            headerBg.removeClass('showBack')
            headerBg.addClass('hideBack')
			this.headerBgIsOn = false;
		} 
	}

	public animateHex(){
		var bottomHex =  $('.bottom .hex');
		var hexOffset = bottomHex.offset().top;
		
		if(hexOffset<=600){
			bottomHex.addClass('showHex');
			this.hexOn = true;
		}
	}

    public repositionVideo(){
        let videoTag:any =  $('video');
        let videoWidth = 1920;
        let videoheight = 1072;

        $('.top').css({
        	'width':this.sWidth,
        	'height':this.sHeight
        })


        let pos = Utils.fitToContainer({
             'containerWidth':this.sWidth,
             'containerHeight':this.sHeight,
             'contentWidth':videoWidth,
             'contentHeight':videoheight,
             'scaleMode':'proportionalOutside',
             'hAlign':'center',
             'vAlign':'top'
         });
        videoTag.css(pos);

    }

    
    private showMore(e){
        console.log(e.target)
        let youtubeId = $(e.currentTarget).data('id');
        EventBus.dispatchEvent("SHOW_VIDEOOVERLAY", {"id":youtubeId, "isYoutube":true})

        let vidName = $(e.currentTarget).data('name');
        ga('send', 'event', 'youtube', 'view', vidName);
    }

	public removeVideo(){
		let videoTag:any =  $('video');
		videoTag[0].src = "";
		videoTag.remove();
		this.hasVideo =  false;
	}

	public resize(e){
		
		super.resize(e);

		var top =  $('.top');

        if(this.hasVideo){
          this.repositionVideo();
        }
		
        if (this.sWidth >= 768 && !this.hasVideo) {
			top.prepend('<video preload  autoplay muted loop ;><source src="" type="video/mp4"></video>')
			top.removeClass("hero")
			this.addVideo();
			this.hasVideo = true;
		} else if (this.sWidth < 768 && this.hasVideo) {
			top.addClass("hero");
			top.css('width', '100%');
			this.removeVideo(); 
		} 


	}

   private openVideo(e) {
	   let youtubeId = $(e.currentTarget).data('id');
	   EventBus.dispatchEvent("SHOW_VIDEOOVERLAY", {"id":youtubeId, "isYoutube":true})

	   let vidName = $(e.currentTarget).data('name');
	   ga('send', 'event', 'youtube', 'view', 'vidName');
   }
}

var page = new Home();