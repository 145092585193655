/// <reference path='Main.ts'/>

class Page{
    
    public main:Main;
    public sWidth:number;
    public sHeight:number;
    public rowHeight:number;
    public mobileBreakpoint:number = 768;
    public baseScrollHeight: number = 5270;
    public visitPageBtn: JQuery;
    

    constructor(){

        this.main = new Main();

        this.bindEvents();
        var div:any = $('p')
        this.orphanFix(div);
        this.resize(null);
        this.visitPageBtn = $('.btn')

        this.visitPageBtn.on('mouseenter touchstart' , ()=>this.rollIn());    
        this.visitPageBtn.on('mouseleave touchend' , ()=>this.rollOut());

        this.addMailTo();
    }

    private addMailTo(){

        var domain = "imposium@mediaocean.com";
        $(".mailTo").append('<a href="mailto:' + domain + '">' + domain + '</a>');
    }

    public rollIn(){
        this.visitPageBtn.addClass('hover');
    }

    public rollOut(){
         this.visitPageBtn.removeClass('hover');
    }

    public bindEvents(){

        $(window).bind('resize', (e)=>this.resize(e));
        $('.container').on('scroll', (e)=>this.checkScroll(e));
    }



    public resize(e){
        this.sWidth = window.innerWidth;
        this.sHeight = window.innerHeight;

        this.rowHeight = Math.round(this.sWidth / 12);

        //resize all of the grid items on the page

        var scope = this;
        $(".hero, .grid-item").each(function(){

            let h;
            let w = $(this).width();
            if(scope.sWidth <= scope.mobileBreakpoint){
                h = '';
            }else{
                let rows = parseInt($(this).attr('data-height'));
                h = scope.rowHeight * rows;
            }
            $(this).css('height', h);
            let resizeWidth = $(this).width();
            if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                $('.rollover').css('left', -resizeWidth-2);
            }
        })
    }


    

    public checkScroll(e){
        var top = $(e.currentTarget).scrollTop();
    }     

    private orphanFix(userOptions){
        var defaults = {
            letterLimit: null,
            prevLimit: null,
            linkFix: false,
            dashes: false
        };

        var wfOptions = $.extend(defaults, userOptions);

        if (userOptions.length) {

            return userOptions.each(function(){

                var $this = $(this);
                var linkFixLastWord;
                
                if ( wfOptions.linkFix ) {
                    var $linkHolder = $this.find('a:last');
                    //find the anchors and wrap them up with a <var> tag to find it later
                    $linkHolder.wrap('<var>');
                    //store the anchor inside
                    var $lastLink = $('var').html();
                    //get the real length of the last word
                    linkFixLastWord = $linkHolder.contents()[0];
                    //remove the anchor
                    $linkHolder.contents().unwrap();
                }

                var contentArray = $(this).html().split(' '),
                    lastWord = contentArray.pop();

                if (contentArray.length <= 1) {
                    // it's a one word element, abort!
                    return;
                }

                function checkSpace(){
                    if (lastWord === ''){
                        // trailing space found, pop it off and check again
                        lastWord = contentArray.pop();
                        checkSpace();
                    }
                }
                checkSpace();
                
                // if contains a dash, use white-space nowrap to stop breaking
                if (wfOptions.dashes) {
                    
                    // all 3 dash types: regular, en, em
                    var dashes = ['-','–','—'];
                
                    $.each(dashes, function(index, dash) {

                        if ( lastWord.indexOf(dash) > 0 ) {
                            lastWord = '<span style="white-space:nowrap;">' + lastWord + '</span>';
                            return false; // break out early
                        }
                        
                    });
                
                }
                
                var prevWord = contentArray[contentArray.length-1];

                //if linkFix is on, check for the letter limit
                if (wfOptions.linkFix) {
                    //if the last word is longer than the limit, stop the script
                    if (wfOptions.letterLimit !== null &&
                        linkFixLastWord.length >= wfOptions.letterLimit
                        ) {

                            $this.find('var').each(function(){
                                $(this).contents().replaceWith($lastLink);
                                $(this).contents().unwrap();
                            });
                            return;

                    //or if the prev word is longer than the limit
                    } else if (wfOptions.prevLimit !== null &&
                               prevWord.length >= wfOptions.prevLimit
                               ) {
                                    $this.find('var').each(function(){
                                        $(this).contents().replaceWith($lastLink);
                                        $(this).contents().unwrap();
                                    });
                                    return;
                    }


                } else {
                    //if the last word is longer than the limit, stop the script
                    if (wfOptions.letterLimit !== null &&
                        lastWord.length >= wfOptions.letterLimit
                        ) {
                            return;
                    } else if (wfOptions.prevLimit !== null &&
                        prevWord.length >= wfOptions.prevLimit
                        ) {
                            return;
                    }
                }

                var content = contentArray.join(' ') + '&nbsp;' + lastWord;
                $this.html(content);

                if (wfOptions.linkFix) {

                    //find the var and put the anchor back in, then unwrap the <var>
                    $this.find('var').each(function(){
                        $(this).contents().replaceWith($lastLink);
                        $(this).contents().unwrap();
                    });
                }

            });

        }
    }

}