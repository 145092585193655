/// <reference path='../scaffolding/Component.ts'/>

class MobileMenu extends Component{
    private isOff: boolean = true;
    private menuIconDiv: JQuery;
    constructor(container:JQuery, data:any, delegate:any) {
        super(container, data, delegate);
        $('.mobileMenuIcon').on('click touchstart', (e) => this.toggleOpen(e));

        this.menuIconDiv = $('.menuIcon');
    }

    private toggleOpen(e){
        e.preventDefault();
        this.container.toggleClass('closed')
        if (this.isOff) {
            $('body').css({ 'overflow-y': 'hidden' });
            this.menuIconDiv.removeClass('icon-menu').addClass('icon-cross')
            this.isOff = false;


            ga('send', 'event', 'menu', 'view', 'menu_close');
        } else {
            $('body').css({ 'overflow-y': 'auto' });
            this.menuIconDiv.removeClass('icon-cross').addClass('icon-menu')
            this.isOff = true;

            ga('send', 'event', 'menu', 'view', 'menu_open');
        }
    }


}
