/// <reference path='../scaffolding/Component.ts'/>
/// <reference path='../scaffolding/EventBus.ts'/>

class VideoOverlay extends Component{
    public videoExtentsion: string = "";
    private videoPlayer:JQuery;
    private videoString:string;
    constructor(container:JQuery, data:any, delegate:any) {
        super(container, data, delegate);
        let placeholder = this.container.find('.videoHolder');
        this.content = container;

        EventBus.addEventListener("SHOW_VIDEOOVERLAY", $.proxy(this.updateVideo, this), this);
        this.content.find('.closeBtn').bind("click touchend", ()=>this.hideMe())
        this.content.find('.videoHolder').bind("click touchend", ()=>this.hideMe())
        this.resize();
        this.videoPlayer = this.content.find('.videoHolder');
       
    }

    private updateVideo(data){
        console.log(data.id)
        if(data.isYoutube){
           this.videoString = '<iframe id="ytplayer" type="text/html" src="https://www.youtube.com/embed/'+data.id+'?autoplay=1&rel=0&modestbranding=1&playsinline=1&color=white" frameborder="0" allowfullscreen>'     
        } else if (data.bitly){ 
            this.videoString = '<video id="ytplayer" preload autoplay><source src="' + data.id + '" type="video/mp4"></video>';     
        } else {
            this.videoString = '<video id="ytplayer" preload autoplay><source src="https://d1i6j3oo0bhuty.cloudfront.net/' + data.id + '" type="video/mp4"></video>';  
        }
        this.videoPlayer.html(this.videoString)
        this.showMe();
    }

    public showMe(){
        // super.showMe();
        this.content.find('.contents').addClass('open');
        setTimeout(()=>{
            $('#ytplayer').addClass('show')
        },300);
    }

    public hideMe(){
        this.content.find('.contents').removeClass('open');
        setTimeout(()=>{
            $('#ytplayer').removeClass('show')
        },300);
        // super.hideMe();
        this.videoPlayer.empty();

    }

    public resize(){
        let sHeight = window.innerHeight;
        this.content.css('height', sHeight - 60);
    }
}
