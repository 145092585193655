var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Component = /** @class */ (function () {
    /**
     * Stores the global vars
     *
     * @param container A jQuery object containing the parent div for this view.
     * @param data      The config JSON data associated with this component.
     * @param delegate  The Class that instantiated this view.
     */
    function Component(container, data, delegate) {
        /**
         * Whether of not this instance is visible or not.
         */
        this.onStage = false;
        /**
         * The delay for setting display:none on this view after the showContent class is removed.
         */
        this.hideDelay = 0;
        /**
         * The delay for adding the showContent class to this view once display:block has been set.
         */
        this.showDelay = 25;
        /**
         * The object containing event listeners added to this specific component
         */
        this.listeners = {};
        /**
         * The object containing event listeners to be fired once added to this component
         */
        this.onceListeners = {};
        /**
         * The class to use for showing this component
         */
        this.displayClass = "block";
        this.container = container;
        this.delegate = delegate;
        this.data = data;
    }
    /**
     * Sets this.content with a JQuery object passed in (usually cloned from Main.templates)
     *
     * @param v JQuery wrapped DOM element
     */
    Component.prototype.setContent = function (v) {
        this.content = v;
        this.container.append(this.content);
    };
    /**
     * Adds an event listener for an event dispatched from this specific instance.
     *
     * @param evt      Event string we want to add an event listener for.
     * @param callback The function we want to call if that event is dispatched.
     * @param caller   Who called this
     */
    Component.prototype.on = function (evt, callback, caller) {
        if (!this.listeners[evt]) {
            this.listeners[evt] = {};
        }
        this.listeners[evt][caller.guid] = callback;
    };
    /**
     * Removes an event listener for an event dispatched from this specific instance.
     *
     * @param evt      Event string we want to remove an event listener for.
     * @param callback The function we want to remove from the listeners array for that event.
     * @param caller   Who called this
     */
    Component.prototype.off = function (evt, callback, caller) {
        var listeners = this.listeners[evt];
        delete listeners[caller.guid];
    };
    /**
     * Dispatches an event to any Classes listening for it from this instance.
     *
     * @param evt  The event we want to dispatch.
     * @param data The data we want to pass back to the event listener function.
     */
    Component.prototype.dispatch = function (evt, data) {
        if (data === void 0) { data = null; }
        var listeners = this.listeners[evt];
        var onceListeners = this.onceListeners[evt];
        for (var key in listeners) {
            if (listeners.hasOwnProperty(key)) {
                listeners[key](data);
            }
        }
        for (var key in onceListeners) {
            if (onceListeners.hasOwnProperty(key)) {
                onceListeners[key](data);
                delete onceListeners[key];
            }
        }
    };
    /**
     * Binds all of the event listeners for this component.
     */
    Component.prototype.bindEvents = function () {
    };
    /**
     * Unbinds all of the Event listeners for this component.
     */
    Component.prototype.unbindEvents = function () {
    };
    /**
     * Adds display block to this.content, and calls showContent
     */
    Component.prototype.showMe = function () {
        var _this = this;
        var style = (this.displayClass == "visible") ? "visibility" : "display";
        this.content.css(style, this.displayClass);
        this.onStage = true;
        clearTimeout(this.displayTimeout);
        this.displayTimeout = setTimeout(function () {
            _this.showContent();
        }, this.showDelay);
    };
    /**
     * Adds display none to this.content, calls hideContent
     */
    Component.prototype.hideMe = function () {
        var _this = this;
        this.hideContent();
        this.onStage = false;
        var style = (this.displayClass == "visible") ? "visibility" : "display";
        var hide = (style == "display") ? "none" : "hidden";
        clearTimeout(this.displayTimeout);
        this.displayTimeout = setTimeout(function () {
            _this.content.css(style, hide);
        }, this.hideDelay);
    };
    /**
     * Adds the showContent class and removes the hideContent class from this component.
     */
    Component.prototype.showContent = function () {
        this.content.removeClass('hideContent');
        this.content.addClass('showContent');
    };
    /**
     * Adds the hideContent class and removes the showContent class from this component.
     */
    Component.prototype.hideContent = function () {
        this.content.removeClass('showContent');
        this.content.addClass('hideContent');
    };
    /**
     * Kills this component, removes the event listeners, removes this.content, and set's itself to null;
     */
    Component.prototype.killMe = function () {
        this.unbindEvents();
        this.content.remove();
        this.content = null;
    };
    return Component;
}());
/// <reference path='../scaffolding/Component.ts'/>
var MobileMenu = /** @class */ (function (_super) {
    __extends(MobileMenu, _super);
    function MobileMenu(container, data, delegate) {
        var _this = _super.call(this, container, data, delegate) || this;
        _this.isOff = true;
        $('.mobileMenuIcon').on('click touchstart', function (e) { return _this.toggleOpen(e); });
        _this.menuIconDiv = $('.menuIcon');
        return _this;
    }
    MobileMenu.prototype.toggleOpen = function (e) {
        e.preventDefault();
        this.container.toggleClass('closed');
        if (this.isOff) {
            $('body').css({ 'overflow-y': 'hidden' });
            this.menuIconDiv.removeClass('icon-menu').addClass('icon-cross');
            this.isOff = false;
            ga('send', 'event', 'menu', 'view', 'menu_close');
        }
        else {
            $('body').css({ 'overflow-y': 'auto' });
            this.menuIconDiv.removeClass('icon-cross').addClass('icon-menu');
            this.isOff = true;
            ga('send', 'event', 'menu', 'view', 'menu_open');
        }
    };
    return MobileMenu;
}(Component));
/**
 * Global event listener class. Used for passing global between views and components.
 */
var EventBus = /** @class */ (function () {
    function EventBus() {
    }
    /**
     * Adds an event listener for a global event.
     *
     * @param evt      Event string we're listening for
     * @param callback The callback function to call if the event is dispatched.
     * @param caller   Who called this
     */
    EventBus.addEventListener = function (evt, callback, caller) {
        if (!EventBus.listeners[evt]) {
            EventBus.listeners[evt] = {};
        }
        EventBus.listeners[evt][caller.uuid] = callback;
    };
    /**
     * Removes a specific event listener for a global event.
     *
     * @param evt      Event string we're removing
     * @param callback The callback function we want to remove.
     * @param caller   Who called this
     */
    EventBus.removeEventListener = function (evt, callback, caller) {
        var listeners = EventBus.listeners[evt];
        delete listeners[caller.uuid];
    };
    /**
     * Adds an event listener for a global event.
     *
     * @param evt  The event we want to dispatch.
     * @param data The data we want to pass into the callback function for that event.
     */
    EventBus.dispatchEvent = function (evt, data) {
        if (data === void 0) { data = null; }
        var listeners = EventBus.listeners[evt];
        for (var key in listeners) {
            if (listeners.hasOwnProperty(key)) {
                listeners[key](data);
            }
        }
    };
    /**
     * Object containing all event listeners, with the key being the event string, and the value being an array of listener functions.
     */
    EventBus.listeners = {};
    return EventBus;
}());
/// <reference path='../scaffolding/Component.ts'/>
/// <reference path='../scaffolding/EventBus.ts'/>
var VideoOverlay = /** @class */ (function (_super) {
    __extends(VideoOverlay, _super);
    function VideoOverlay(container, data, delegate) {
        var _this = _super.call(this, container, data, delegate) || this;
        _this.videoExtentsion = "";
        var placeholder = _this.container.find('.videoHolder');
        _this.content = container;
        EventBus.addEventListener("SHOW_VIDEOOVERLAY", $.proxy(_this.updateVideo, _this), _this);
        _this.content.find('.closeBtn').bind("click touchend", function () { return _this.hideMe(); });
        _this.content.find('.videoHolder').bind("click touchend", function () { return _this.hideMe(); });
        _this.resize();
        _this.videoPlayer = _this.content.find('.videoHolder');
        return _this;
    }
    VideoOverlay.prototype.updateVideo = function (data) {
        console.log(data.id);
        if (data.isYoutube) {
            this.videoString = '<iframe id="ytplayer" type="text/html" src="https://www.youtube.com/embed/' + data.id + '?autoplay=1&rel=0&modestbranding=1&playsinline=1&color=white" frameborder="0" allowfullscreen>';
        }
        else if (data.bitly) {
            this.videoString = '<video id="ytplayer" preload autoplay><source src="' + data.id + '" type="video/mp4"></video>';
        }
        else {
            this.videoString = '<video id="ytplayer" preload autoplay><source src="https://d1i6j3oo0bhuty.cloudfront.net/' + data.id + '" type="video/mp4"></video>';
        }
        this.videoPlayer.html(this.videoString);
        this.showMe();
    };
    VideoOverlay.prototype.showMe = function () {
        // super.showMe();
        this.content.find('.contents').addClass('open');
        setTimeout(function () {
            $('#ytplayer').addClass('show');
        }, 300);
    };
    VideoOverlay.prototype.hideMe = function () {
        this.content.find('.contents').removeClass('open');
        setTimeout(function () {
            $('#ytplayer').removeClass('show');
        }, 300);
        // super.hideMe();
        this.videoPlayer.empty();
    };
    VideoOverlay.prototype.resize = function () {
        var sHeight = window.innerHeight;
        this.content.css('height', sHeight - 60);
    };
    return VideoOverlay;
}(Component));
/// <reference path='Main.ts'/>
/**
 * Helper functions available to all other Classes.
 */
var Utils = /** @class */ (function () {
    function Utils() {
    }
    //Create an element of type 'type' and style it
    Utils.createEl = function (type, style, parent, elClass) {
        if (parent === void 0) { parent = null; }
        if (elClass === void 0) { elClass = null; }
        var el = document.createElement(type);
        for (var i in style) {
            if (i == 'transform') {
                el.style[Main.xform + 'Transform'] = style[i];
            }
            else {
                el.style[i] = style[i];
            }
        }
        //el.style = style;
        if (elClass) {
            el.setAttribute('class', elClass);
        }
        if (parent) {
            parent.appendChild(el);
        }
        return el;
    };
    Utils.easeQuadOut = function (e0, e1, t) {
        return e0 + (e1 - e0) * (t * (2 - t));
    };
    Utils.wrapText = function (context, text, x, y, maxWidth, lineHeight) {
        y = lineHeight;
        var words = text.split(' ');
        var line = '';
        for (var n = 0; n < words.length; n++) {
            var testLine = line + words[n] + ' ';
            var metrics = context.measureText(testLine);
            var testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                context.fillText(line, x, y);
                line = words[n] + ' ';
                y += lineHeight;
            }
            else {
                line = testLine;
            }
        }
        context.fillText(line, x, y);
    };
    Utils.pow2floor = function (v) {
        v++;
        var p = 1;
        while (v >>= 1) {
            p <<= 1;
        }
        return p;
    };
    Utils.pow2ceil = function (v) {
        v--;
        var p = 2;
        while (v >>= 1) {
            p <<= 1;
        }
        return p;
    };
    Utils.calcCameraView = function (camera, objZ) {
        var distance = camera.position.z + -objZ;
        var vFOV, height, hFOV, width;
        vFOV = (camera.fov * Math.PI / 180);
        height = 2 * Math.tan(((camera.fov * Math.PI / 180) / 2)) * distance;
        hFOV = 2 * Math.atan(Math.tan(vFOV / 2) * camera.aspect);
        width = 2 * Math.tan((hFOV / 2)) * distance;
        return { width: width, height: height };
    };
    // //calculates the pixel distance between two 3d points
    // static distanceBetween3DPoints(p1:THREE.Vector3, p2:THREE.Vector3):number {
    //     return Math.round(Math.sqrt(((p2.x - p1.x) * (p2.x - p1.x)) + ((p2.y - p1.y) * (p2.y - p1.y)) + ((p2.z - p1.z) * (p2.z - p1.z))));
    // }
    /* --------------------------------------------------------*/
    /* --------------------- MISC HELPERS ---------------------*/
    /* --------------------------------------------------------*/
    /**
     * Resizes a rectangle to fit into another rectangle using different positioning and scale properties to crop / position.
     * @data    The data object containig all of the paraeters for the calculation.
     */
    Utils.fitToContainer = function (data) {
        var newH, newW, top, left;
        var aspectRatio = data.contentWidth / data.contentHeight;
        //scale
        if (data.scaleMode == "proportionalInside") {
            newW = data.containerWidth;
            newH = newW / aspectRatio;
            if (newH > data.containerHeight) {
                newH = data.containerHeight;
                newW = newH * aspectRatio;
            }
        }
        else if (data.scaleMode == "proportionalOutside") {
            newW = data.containerWidth;
            newH = newW / aspectRatio;
            if (newH < data.containerHeight) {
                newH = data.containerHeight;
                newW = newH * aspectRatio;
            }
        }
        else if (data.scaleMode == "none" || !data.scaleMode) {
            newW = data.contentWidth;
            newH = data.contentHeight;
        }
        if (data.maxWidth) {
            if (newW > data.maxWidth) {
                newW = data.maxWidth;
                newH = newW / aspectRatio;
            }
        }
        if (data.maxHeight) {
            if (newH > data.maxHeight) {
                newH = data.maxHeight;
                newW = newH * aspectRatio;
            }
        }
        //fit
        left = (data.hAlign == "left") ? 0 : (data.hAlign == "right") ? -(newW - data.containerWidth) : (data.containerWidth - newW) / 2;
        top = (data.vAlign == "top") ? 0 : (data.vAlign == "bottom") ? -(newH - data.containerHeight) : (data.containerHeight - newH) / 2;
        return {
            'width': newW,
            'height': newH,
            'top': top,
            'left': left
        };
    };
    /**
     * Generates a UUID and returns it.
     */
    Utils.generateUUID = function () {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
        });
        return uuid;
    };
    /**
     * Generates a unique id, based off of the langth passed in.
     * @numChars    How may characters we want the (psuedo) unique ID to contain.
     */
    Utils.generateID = function (numChars) {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < numChars; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        return text;
    };
    /**
     * Wrapper function to make an ajax request.
     * @data                The JSON data we want to pas in.
     * @successCallback     Callback function for on success.
     * @errorCallback       Callback function for error.
     */
    Utils.ajax = function (data, successCallback, errorCallback, ajaxUrl) {
        if (successCallback === void 0) { successCallback = null; }
        if (errorCallback === void 0) { errorCallback = null; }
        if (ajaxUrl === void 0) { ajaxUrl = null; }
        $.ajax({
            type: 'POST',
            url: 'ajax.cms.php',
            data: data,
            dataType: 'json',
            success: function (response) {
                if (successCallback) {
                    successCallback(response);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                if (errorCallback) {
                    errorCallback(jqXHR.responseText);
                }
            }
        });
    };
    /**
     * Pushes a browser state
     * @state   The state we want to push.
     */
    Utils.pushState = function (state) {
        // History['pushState']({state:1}, Main.config.pageTitle, state);
        History['pushState']({ state: 1 }, Main.config.title, state);
    };
    /**
     *Opens a popup window centered in the screen
     */
    Utils.openWindow = function (url, width, height) {
        var windowSize = {
            'width': width,
            'height': height,
            'left': (screen.width / 2) - (width / 2),
            'top': (screen.height / 2) - (height / 2 + 100)
        };
        var windowFeatures = "width=" + windowSize.width + ",height=" + windowSize.height + ",status,resizable,scrollbars,modal,alwaysRaised";
        windowFeatures += ",left=" + windowSize.left + ",top=" + windowSize.top + "screenX=" + windowSize.left + ",screenY=" + windowSize.top;
        window.open(url, '' + new Date().getTime() + '', windowFeatures);
    };
    /* --------------------------------------------------------*/
    /* ------------------------ STRING ------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Capitalises the first letter of a string.
     * @str     The string you want to capitalise.
     */
    Utils.capitalize = function (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    /**
     * Returns a formatted string for displaying the file size, from bytes.
     * @bytes       The filesize, in bytes.
     */
    Utils.formatBytes = function (bytes) {
        if (bytes >= 1000000000) {
            bytes = (bytes / 1000000000).toFixed(2) + ' GB';
        }
        else if (bytes >= 1000000) {
            bytes = (bytes / 1000000).toFixed(2) + ' MB';
        }
        else if (bytes >= 1000) {
            bytes = (bytes / 1000).toFixed(2) + ' KB';
        }
        else if (bytes > 1) {
            bytes = bytes + ' bytes';
        }
        else if (bytes == 1) {
            bytes = bytes + ' byte';
        }
        else {
            bytes = '0 byte';
        }
        return bytes;
    };
    /* --------------------------------------------------------*/
    /* ------------------------ OBJECT ------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Counts the key/value pairs in an object.
     * @obj     The object you want to get a "length" from.
     */
    Utils.objSize = function (obj) {
        var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key))
                size++;
        }
        return size;
    };
    /* --------------------------------------------------------*/
    /* ----------------------- NUMBERS ------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Detects if a value is numberic or not.
     * @n   The value we want to check.
     */
    Utils.isNumeric = function (n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    };
    /**
     * Detects if a number is odd or event
     * @num     The value we want to check.
     */
    Utils.isOdd = function (num) {
        return (num % 2) == 16;
    };
    /**
     * Contain a number to a min and max.
     */
    Utils.clamp = function (min, max, val) {
        if (val < min)
            return min;
        if (val > max)
            return max;
        return val;
    };
    /* --------------------------------------------------------*/
    /* ------------------------- MATH -------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Convert degrees to radians.
     */
    Utils.degreesToRadians = function (degrees) {
        return degrees * Math.PI / 180;
    };
    /**
     * Convert radians to degrees
     */
    Utils.radiansToDegrees = function (radians) {
        return radians * 180 / Math.PI;
    };
    /**
     * Calculates the distance between two points in 2D space.
     */
    Utils.lineDistance = function (point1, point2) {
        var xs = 0;
        var ys = 0;
        xs = point2.x - point1.x;
        xs = xs * xs;
        ys = point2.y - point1.y;
        ys = ys * ys;
        return Math.sqrt(xs + ys);
    };
    /**
     * Calculates the angle in degrees between two points
     */
    Utils.calcAngle = function (p1, p2) {
        var calcAngle = Math.atan2(p1.x - p2.x, p1.y - p2.y) * (180 / Math.PI);
        if (calcAngle < 0)
            calcAngle = Math.abs(calcAngle);
        else
            calcAngle = 360 - calcAngle;
        return calcAngle;
    };
    /**
     * Returns a random number between 2 numbers
     */
    Utils.randomFromInterval = function (from, to) {
        return Math.floor(Math.random() * (to - from + 1) + from);
    };
    /* --------------------------------------------------------*/
    /* ------------------------- ARRAY ------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Switches the position of two array elements.
     * @array       The array containing both elements.
     * @a           The index of the first element.
     * @b           The index of the second element.
     * @return      The array with the elements switched.
     */
    Utils.swapArrayElements = function (array, a, b) {
        var temp = array[a];
        array[a] = array[b];
        array[b] = temp;
        return array;
    };
    /**
     * Removes one of more elements from an array.
     * @array       The array containing all of the elements.
     * @from        The first element we want to remove (and the last, if @to isn't set).
     * @to          The index of the last element we want to remove.
     * @return      The original array with the elements removed.
     */
    Utils.removeFromArray = function (array, from, to) {
        var rest = array.slice((to || from) + 1 || array.length);
        array.length = from < 0 ? array.length + from : from;
        return array.push.apply(array, rest);
    };
    /**
     * Randomly shuffles the contents of an array.
     * @array       The array containing all of the elements.
     */
    Utils.shuffleArray = function (array) {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    };
    /* --------------------------------------------------------*/
    /* -------------------------- JSON ------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Checks if a string is valid json.
     * @str     The string you want to check.
     */
    Utils.isValidJSON = function (str) {
        try {
            JSON.parse(str);
        }
        catch (e) {
            return false;
        }
        return true;
    };
    /**
     * Formats a JSON sting with line breaks for displaying pretty JSON.
     * @str     The string you want to format.
     */
    Utils.formatJSONString = function (str) {
        var jsonObj = JSON.parse(str);
        var jsonPretty = JSON.stringify(jsonObj, null, '\t');
        return jsonPretty;
    };
    /* --------------------------------------------------------*/
    /* ----------------- BROWSER / OS DETECTION ---------------*/
    /* --------------------------------------------------------*/
    /**
     * Detects the operating system on a mobile device, returns and os and the version.
     */
    Utils.detectMobileOS = function () {
        var mobileOS;
        var mobileOSver;
        var ua = navigator.userAgent;
        var uaindex;
        // determine OS
        if (ua.match(/iPad/i) || ua.match(/iPhone/i)) {
            mobileOS = 'iOS';
            uaindex = ua.indexOf('OS ');
        }
        else if (ua.match(/Android/i)) {
            mobileOS = 'Android';
            uaindex = ua.indexOf('Android ');
        }
        else {
            mobileOS = 'unknown';
        }
        // determine version
        if (mobileOS === 'iOS' && uaindex > -1) {
            mobileOSver = ua.substr(uaindex + 3, 3).replace('_', '.');
        }
        else if (mobileOS === 'Android' && uaindex > -1) {
            mobileOSver = ua.substr(uaindex + 8, 3);
        }
        else {
            mobileOSver = 'unknown';
        }
        var num = Number(mobileOSver);
        return { "os": mobileOS, "ver": num };
    };
    /* --------------------------------------------------------*/
    /* -------------------- FEATURE DETECTION -----------------*/
    /* --------------------------------------------------------*/
    /**
     * Detects if the current browser can play the mp4 video format or now.
     */
    Utils.canPlayMP4 = function () {
        var canPlay = false;
        var v = document.createElement('video');
        if (v.canPlayType && v.canPlayType('video/mp4').replace(/no/, '')) {
            canPlay = true;
        }
        return canPlay;
    };
    /* --------------------------------------------------------*/
    /* ----------------------- DATE / TIME --------------------*/
    /* --------------------------------------------------------*/
    Utils.clientTimezone = function (date) {
        return date.addHours(-(new Date().getTimezoneOffset() / 60));
    };
    Utils.formatDate = function (date, format) {
        var d = Utils.clientTimezone(date);
        return date.toString(format).replace(' 0:', ' 12:'); // Fix date.js bug to show 12:00am instead of 0:00am
    };
    /* --------------------------------------------------------*/
    /* ------------------------ COOKIES -----------------------*/
    /* --------------------------------------------------------*/
    /**
     *Sets a cookie to the document object
     * @c_name      The name for the new cookie.
     * @value       The data to set on this cookie.
     * @exdays      How many days befor this cookie expires.
     */
    Utils.setCookie = function (c_name, value, exdays) {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        var c_value = value + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString()) + ";path=/";
        document.cookie = c_name + "=" + c_value;
    };
    /**
     * Retrives a cookie from the document object using the cookies name
     * @c_name      Label of the cookie you want to retrieve.
     */
    Utils.getCookie = function (c_name) {
        var c_value = document.cookie;
        var c_start = c_value.indexOf(" " + c_name + "=");
        if (c_start == -1) {
            c_start = c_value.indexOf(c_name + "=");
        }
        if (c_start == -1) {
            c_value = null;
        }
        else {
            c_start = c_value.indexOf("=", c_start) + 1;
            var c_end = c_value.indexOf(";", c_start);
            if (c_end == -1) {
                c_end = c_value.length;
            }
            c_value = c_value.substring(c_start, c_end);
        }
        return c_value;
    };
    /* --------------------------------------------------------*/
    /* ---------------------- VALIDATION ----------------------*/
    /* --------------------------------------------------------*/
    /**
     * Validates an email address.
     */
    Utils.realEmail = function (addressToTest) {
        var regPattern = /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
        return regPattern.test(addressToTest);
    };
    return Utils;
}());
var StoryUtils = /** @class */ (function () {
    function StoryUtils() {
    }
    StoryUtils.getFirstCutTime = function (story, sceneId) {
        var time = 0;
        var scene = StoryUtils.getScene(story, sceneId);
        if (scene) {
            var cuts = scene.sceneData.cuts;
            var first = cuts[0];
            if (first) {
                time = first.startFrame / scene.sceneData.videoFile.rate;
            }
        }
        return time;
    };
    StoryUtils.getScene = function (story, sceneId, params) {
        var r = null;
        if (story.acts) {
            for (var key in story.acts) {
                if (story.acts.hasOwnProperty(key)) {
                    var act = story.acts[key];
                    if (act.scenes) {
                        for (var s in act.scenes) {
                            if (act.scenes.hasOwnProperty(s)) {
                                var scene = act.scenes[s];
                                if (scene.id == sceneId) {
                                    r = scene;
                                    return r;
                                }
                            }
                        }
                    }
                }
            }
        }
        return r;
    };
    StoryUtils.getAct = function (story, actId, params) {
        var act = null;
        if (story.acts) {
            if (story.acts.hasOwnProperty(actId)) {
                act = story.acts[actId];
                return act;
            }
        }
        return act;
    };
    StoryUtils.getInventory = function (story, invId) {
        var r = null;
        //run through all of the acts, and their scenes, and look for the label matching this ID
        if (story.acts) {
            for (var key in story.acts) {
                if (story.acts.hasOwnProperty(key)) {
                    var act = story.acts[key];
                    if (act.inventory) {
                        for (var s in act.inventory) {
                            if (act.inventory.hasOwnProperty(s)) {
                                var inv = act.inventory[s];
                                if (inv.id == invId) {
                                    r = inv;
                                    return r;
                                }
                            }
                        }
                    }
                }
            }
        }
        return r;
    };
    return StoryUtils;
}());
/// <reference path='../partial/MobileMenu.ts'/>
/// <reference path='../partial/VideoOverlay.ts'/>
/// <reference path='Utils.ts'/>
/**
 * Main static class, holds all global config variables and functions for all views and components.
 */
var Main = /** @class */ (function () {
    /**
     * Creates a new Main instance, sets the templates for all views and components to use, creates a global socket connection, and gets the saved state from a cookie.
     *
     * @param config    Configuration variables passed in from config.php.
     */
    function Main(config) {
        var _this = this;
        if (config === void 0) { config = null; }
        //set the php config variables
        Main.config = config;
        this.setPrefixes();
        Main.templates = $("#templates");
        $(window).bind('resize orientationchange', function () { return _this.resize(); });
        this.resize();
        var mobileMenu = new MobileMenu($('.mobileMenu'), {}, this);
        var videoOverlay = new VideoOverlay($('.videoOverlay'), {}, this);
        var mobile = Utils.detectMobileOS();
        if (mobile.os == 'unknown') {
            Main.isMobile = false;
        }
    }
    Main.prototype.resize = function () {
        Main.sWidth = $(window).width();
        Main.sHeight = $(window).height();
    };
    /**
     * Sets Main.xform and Main.transition by detecting which prefix exists on the current browser.
     */
    Main.prototype.setPrefixes = function () {
        var view = document.createElement('div');
        var prefixes = ['webkit', 'Moz', 'O', 'ms'];
        for (var i = 0; i < prefixes.length; i++) {
            var prefix = prefixes[i];
            var e = prefix + 'Transform';
            var t = prefix + 'Transition';
            if (typeof view.style[e] !== 'undefined') {
                Main.xform = e;
                Main.transition = t;
            }
        }
        view = null;
    };
    Main.isMobile = true;
    return Main;
}());
/// <reference path='Main.ts'/>
var Page = /** @class */ (function () {
    function Page() {
        var _this = this;
        this.mobileBreakpoint = 768;
        this.baseScrollHeight = 5270;
        this.main = new Main();
        this.bindEvents();
        var div = $('p');
        this.orphanFix(div);
        this.resize(null);
        this.visitPageBtn = $('.btn');
        this.visitPageBtn.on('mouseenter touchstart', function () { return _this.rollIn(); });
        this.visitPageBtn.on('mouseleave touchend', function () { return _this.rollOut(); });
        this.addMailTo();
    }
    Page.prototype.addMailTo = function () {
        var domain = "imposium@mediaocean.com";
        $(".mailTo").append('<a href="mailto:' + domain + '">' + domain + '</a>');
    };
    Page.prototype.rollIn = function () {
        this.visitPageBtn.addClass('hover');
    };
    Page.prototype.rollOut = function () {
        this.visitPageBtn.removeClass('hover');
    };
    Page.prototype.bindEvents = function () {
        var _this = this;
        $(window).bind('resize', function (e) { return _this.resize(e); });
        $('.container').on('scroll', function (e) { return _this.checkScroll(e); });
    };
    Page.prototype.resize = function (e) {
        this.sWidth = window.innerWidth;
        this.sHeight = window.innerHeight;
        this.rowHeight = Math.round(this.sWidth / 12);
        //resize all of the grid items on the page
        var scope = this;
        $(".hero, .grid-item").each(function () {
            var h;
            var w = $(this).width();
            if (scope.sWidth <= scope.mobileBreakpoint) {
                h = '';
            }
            else {
                var rows = parseInt($(this).attr('data-height'));
                h = scope.rowHeight * rows;
            }
            $(this).css('height', h);
            var resizeWidth = $(this).width();
            if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                $('.rollover').css('left', -resizeWidth - 2);
            }
        });
    };
    Page.prototype.checkScroll = function (e) {
        var top = $(e.currentTarget).scrollTop();
    };
    Page.prototype.orphanFix = function (userOptions) {
        var defaults = {
            letterLimit: null,
            prevLimit: null,
            linkFix: false,
            dashes: false
        };
        var wfOptions = $.extend(defaults, userOptions);
        if (userOptions.length) {
            return userOptions.each(function () {
                var $this = $(this);
                var linkFixLastWord;
                if (wfOptions.linkFix) {
                    var $linkHolder = $this.find('a:last');
                    //find the anchors and wrap them up with a <var> tag to find it later
                    $linkHolder.wrap('<var>');
                    //store the anchor inside
                    var $lastLink = $('var').html();
                    //get the real length of the last word
                    linkFixLastWord = $linkHolder.contents()[0];
                    //remove the anchor
                    $linkHolder.contents().unwrap();
                }
                var contentArray = $(this).html().split(' '), lastWord = contentArray.pop();
                if (contentArray.length <= 1) {
                    // it's a one word element, abort!
                    return;
                }
                function checkSpace() {
                    if (lastWord === '') {
                        // trailing space found, pop it off and check again
                        lastWord = contentArray.pop();
                        checkSpace();
                    }
                }
                checkSpace();
                // if contains a dash, use white-space nowrap to stop breaking
                if (wfOptions.dashes) {
                    // all 3 dash types: regular, en, em
                    var dashes = ['-', '–', '—'];
                    $.each(dashes, function (index, dash) {
                        if (lastWord.indexOf(dash) > 0) {
                            lastWord = '<span style="white-space:nowrap;">' + lastWord + '</span>';
                            return false; // break out early
                        }
                    });
                }
                var prevWord = contentArray[contentArray.length - 1];
                //if linkFix is on, check for the letter limit
                if (wfOptions.linkFix) {
                    //if the last word is longer than the limit, stop the script
                    if (wfOptions.letterLimit !== null &&
                        linkFixLastWord.length >= wfOptions.letterLimit) {
                        $this.find('var').each(function () {
                            $(this).contents().replaceWith($lastLink);
                            $(this).contents().unwrap();
                        });
                        return;
                        //or if the prev word is longer than the limit
                    }
                    else if (wfOptions.prevLimit !== null &&
                        prevWord.length >= wfOptions.prevLimit) {
                        $this.find('var').each(function () {
                            $(this).contents().replaceWith($lastLink);
                            $(this).contents().unwrap();
                        });
                        return;
                    }
                }
                else {
                    //if the last word is longer than the limit, stop the script
                    if (wfOptions.letterLimit !== null &&
                        lastWord.length >= wfOptions.letterLimit) {
                        return;
                    }
                    else if (wfOptions.prevLimit !== null &&
                        prevWord.length >= wfOptions.prevLimit) {
                        return;
                    }
                }
                var content = contentArray.join(' ') + '&nbsp;' + lastWord;
                $this.html(content);
                if (wfOptions.linkFix) {
                    //find the var and put the anchor back in, then unwrap the <var>
                    $this.find('var').each(function () {
                        $(this).contents().replaceWith($lastLink);
                        $(this).contents().unwrap();
                    });
                }
            });
        }
    };
    return Page;
}());
/// <reference path='../scaffolding/Page.ts'/>
/// <reference path='../scaffolding/Utils.ts'/>
// / <reference path='../partial/IsoSphere.ts'/>
var Home = /** @class */ (function (_super) {
    __extends(Home, _super);
    // public sphere:Nickel.IsoSphere;
    function Home() {
        var _this = _super.call(this) || this;
        _this.hasVideo = false;
        _this.headerBgIsOn = false;
        _this.hexOn = false;
        _this.lineHasGrown = false;
        $('.youtube').bind("click touchstart", function (e) { return _this.openVideo(e); });
        ga('send', 'pageview', '/');
        var navItem = $('.navItem');
        if (_this.sWidth >= 768) {
            $('.container').on('scroll', function (e) { return _this.checkScrollPosition(e); });
            navItem.addClass('hide');
            navItem.addClass('animateNavItem');
            var headerBg = $(".headerBg");
            headerBg.css('opacity', '0');
        }
        $('.ctaBtn').bind('click touchend', function (e) { return _this.showMore(e); });
        return _this;
    }
    Home.prototype.addVideo = function () {
        var videoTag = $('video');
        videoTag[0].src = "/mov/home.mp4";
        videoTag[0].play();
        this.hasVideo = true;
        if (!this.lineHasGrown) {
            videoTag.on('canplay canplaythrough', function () {
                $(".ruleHolder .rule").addClass("grow");
            });
            this.lineHasGrown = true;
        }
        this.repositionVideo();
    };
    Home.prototype.checkScrollPosition = function (e) {
        var target = $(e.currentTarget);
        this.animateHeader(target);
        if (!this.hexOn) {
            this.animateHex();
        }
    };
    Home.prototype.animateHeader = function (target) {
        var top = target.scrollTop();
        var overTop = $(".overview").offset().top;
        var diff = top - overTop;
        var headerBg = $(".headerBg");
        if (top >= 20 && !this.headerBgIsOn) {
            headerBg.addClass('showBack');
            headerBg.removeClass('hideBack');
            this.headerBgIsOn = true;
        }
        else if (top <= 20 && this.headerBgIsOn) {
            headerBg.removeClass('showBack');
            headerBg.addClass('hideBack');
            this.headerBgIsOn = false;
        }
    };
    Home.prototype.animateHex = function () {
        var bottomHex = $('.bottom .hex');
        var hexOffset = bottomHex.offset().top;
        if (hexOffset <= 600) {
            bottomHex.addClass('showHex');
            this.hexOn = true;
        }
    };
    Home.prototype.repositionVideo = function () {
        var videoTag = $('video');
        var videoWidth = 1920;
        var videoheight = 1072;
        $('.top').css({
            'width': this.sWidth,
            'height': this.sHeight
        });
        var pos = Utils.fitToContainer({
            'containerWidth': this.sWidth,
            'containerHeight': this.sHeight,
            'contentWidth': videoWidth,
            'contentHeight': videoheight,
            'scaleMode': 'proportionalOutside',
            'hAlign': 'center',
            'vAlign': 'top'
        });
        videoTag.css(pos);
    };
    Home.prototype.showMore = function (e) {
        console.log(e.target);
        var youtubeId = $(e.currentTarget).data('id');
        EventBus.dispatchEvent("SHOW_VIDEOOVERLAY", { "id": youtubeId, "isYoutube": true });
        var vidName = $(e.currentTarget).data('name');
        ga('send', 'event', 'youtube', 'view', vidName);
    };
    Home.prototype.removeVideo = function () {
        var videoTag = $('video');
        videoTag[0].src = "";
        videoTag.remove();
        this.hasVideo = false;
    };
    Home.prototype.resize = function (e) {
        _super.prototype.resize.call(this, e);
        var top = $('.top');
        if (this.hasVideo) {
            this.repositionVideo();
        }
        if (this.sWidth >= 768 && !this.hasVideo) {
            top.prepend('<video preload  autoplay muted loop ;><source src="" type="video/mp4"></video>');
            top.removeClass("hero");
            this.addVideo();
            this.hasVideo = true;
        }
        else if (this.sWidth < 768 && this.hasVideo) {
            top.addClass("hero");
            top.css('width', '100%');
            this.removeVideo();
        }
    };
    Home.prototype.openVideo = function (e) {
        var youtubeId = $(e.currentTarget).data('id');
        EventBus.dispatchEvent("SHOW_VIDEOOVERLAY", { "id": youtubeId, "isYoutube": true });
        var vidName = $(e.currentTarget).data('name');
        ga('send', 'event', 'youtube', 'view', 'vidName');
    };
    return Home;
}(Page));
var page = new Home();
